<template>
<div>
  <el-upload
      :disabled='disabled'
      v-if="showUpload"
      ref="upload"
      class="avatar-uploader"
      :action="uploadInterfaceIp"
      v-bind:on-progress="uploadVideoProcess"
      v-bind:on-success="handleSuccess"
      v-bind:before-upload="beforeUploadVideo"
      v-bind:show-file-list="false"
  >
    <video
        v-if="videoForm.showVideoPath != '' && !videoFlag"
        v-bind:src="videoForm.showVideoPath"
        class="avatar video-avatar"
        controls="controls">
    </video>
    <i v-else-if="videoForm.showVideoPath == '' && !videoFlag"
       class="el-icon-plus avatar-uploader-icon"
    ></i>
    <el-progress v-if="videoFlag == true" type="circle"
                 v-bind:percentage="videoUploadPercent"
                 style="margin-top: 7px"></el-progress>
  </el-upload>
  <el-button type="danger" size="mini" v-if="Uploadcancel"   @click="canceypload">取消上传</el-button>
  <!-- 删除已上传视频的按钮 -->
  <el-button type="danger" size="mini" v-if="videoForm.showVideoPath !== '' && deleteinfo" @click="deleteVideo">删除视频</el-button>
  <div >只能上传200MB内mp4,mov,视频格式</div>
</div>

</template>

<script>
    import {baseConfig} from "@/utils/config";
    import {tokenCookies} from "@storage/cookies";
    import {MessageError, MessageWarning,MessageSuccess} from "@custom/message";
    import {createFullImageUrl} from "@/components/Upload/index";
    import {vivedperfectApi} from "@/api/work-order-management";

    export default {
        props: {
            // 文件的唯一值，当前系统为uuid
            uuid: {
                type: String,
                default: "",
            },
            name: {
                type: String,
                default: "file",
            },
            accept: {
                type: String,
                default: ".jpg,.jpeg,.png,.gif",
            },
            // 像素大小[width，height]
            pixel: {
                type: Array,
                default: () => [],
            },
            // 是否提示备注
            showTip: {
                type: Boolean,
                default: true,
            },
          // 是否有删除，默认有
          deletetype: {
            type: Boolean,
            default: true,
          },
          // 是否让上传，默认让
          disabledtype: {
            type: Boolean,
            default: false,
          },
        },
        name: "upload-video",
        data() {
            return {
              disabled:this.disabledtype,
              deleteinfo:this.deletetype,
              showUpload:true,
              Uploadcancel:false,
              imageUrl: this.uuid,
              uploadInterfaceIp: baseConfig.baseURL + "/applet/rent/files/upload",
              videoFlag: false,
              //是否显示进度条
              videoUploadPercent: "",
              //进度条的进度，
              isShowUploadVideo: false,
              //显示上传按钮
              videoForm: {
                showVideoPath: "",  //回显的变量
              },
            };
        },
        watch: {
            uuid: {
                handler() {
                    // 回显图片
                    if (!this.uuid) return;
                  vivedperfectApi(this.uuid).then(res => {
                    console.log(res.msg)
                    this.videoForm.showVideoPath =res.msg;
                  })
                  // this.videoForm.showVideoPath = 'https://img.neoyon.com/fuhang/2024/03/27/553a05f2bd1dd7034d9b54a4ff696157';
                },
                immediate: true
            }
        },
        methods: {
          // 用户点击删除按钮时触发，发送删除视频事件到父组件
          deleteVideo() {
            // 触发名为 "delete-video" 的自定义事件，并传递参数
            this.$emit('delete-video');
            this.showUpload = false
            this.videoForm.showVideoPath = ''
            this.$nextTick(() => { 
              this.showUpload = true;
            })
            MessageSuccess('删除视频成功')
          },
          canceypload(){
            // this.loading = false
            this.isShowUploadVideo = true;
            this.videoFlag = false;
            this.videoUploadPercent = 0;
            this.$refs.upload.abort();
            MessageSuccess('取消上传成功')
            this.Uploadcancel = false
          },
          //上传前回调
          beforeUploadVideo(file) {
            console.log('file',file)
            this.Uploadcancel = true
            this.fileName =  file.name

            var fileSize = file.size /  1024 / 1024 < 200; 
            console.log('fileSize',fileSize); //控制大小  修改50的值即可
            // this.fileName = name.substring(0, extensionIndex);  // 文件名称
            if (
                [
                  "video/mp4",
                  "video/ogg",
                  "video/flv",
                  "video/avi",
                  "video/wmv",
                  "video/rmvb",
                  "",
                  "video/quicktime",//支持mov
                ].indexOf(file.type) == -1     //控制格式
            ) {
              MessageWarning("请上传正确的视频格式");
              return false;
            }
            if (!fileSize) {
              MessageWarning("视频大小不能超过200MB");
              return false;
            }
            this.isShowUploadVideo = false;
          },
          //进度条
          uploadVideoProcess(event, file, fileList) {    //注意在data中添加对应的变量名
            this.videoFlag = true;
            console.log('videoUploadPercent',file);
            this.videoUploadPercent = file.percentage.toFixed(0) * 1;
            console.log('this.videoUploadPercent',this.videoUploadPercent);
          },
          //上传成功回调
          handleSuccess(res, file) {
            this.Uploadcancel = false

            this.isShowUploadVideo = true;
            this.videoFlag = false;
            this.videoUploadPercent = 0;
            // /hicity//applet/rent/files/upload
            console.log(res.returnObject.info);
            let info  = res.returnObject.info
            console.log(this.uploadInterfaceIp)
            const fileName = this.fileName;
            console.log( baseConfig.baseURL + "/applet/rent/files/upload")
            this.$emit("on-success", {fileName, file, info});
            //后台上传数据
            if (res.resultCode == 1000) {
              vivedperfectApi(info.uuid).then(res => {
                console.log(res.msg)
                this.videoForm.showVideoPath =res.msg;
              })
              // this.videoForm.showVideoPath = 'https://img.neoyon.com/fuhang/2024/03/27/553a05f2bd1dd7034d9b54a4ff696157';    //上传成功后端返回视频地址 回显
            } else {
              MessageError("上传失败！");
            }
            if(!res){
              MessageError("上传失败！");
            }
          },
        },
    };
</script>

<style lang="scss" scoped>
.avatar-uploader-icon {
  border: 1px dashed #d9d9d9 !important;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9 !important;
  border-radius: 6px !important;
  position: relative !important;
  overflow: hidden !important;
}
.avatar-uploader .el-upload:hover {
  border: 1px dashed #d9d9d9 !important;
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 230px;
  height: 158px;
  line-height: 158px;
  text-align: center;
}
.avatar {
  width: 230px;
  height: 158px;
  display: block;
}
</style>
