// 工单管理
import request from '@http';

// 2022/04/24 王江毅 工单列表
export function getWordList(params) {
    return request({
        method: 'GET',
        url: '/api/gzf/word/list',
        params
    })
}

// 2022/06/10 王江毅 工单导出
export function wordExport(params) {
    return request({
        method: 'GET',
        url: '/api/gzf/word/export',
        params,
        responseType: 'blob',
    })
}

// 2022/04/25 王江毅 汇款
export function wordAudit(data) {
    return request({
        method: 'PUT',
        url: '/api/gzf/word/audit',
        data
    })
}

// 2022/04/25 王江毅 汇款
export function getToContratAmount(params) {
    return request({
        method: 'get',
        url: `/api/gzf/word/getToContratAmount`,
        params
    })
}

// 2022/06/30 王江毅 汇款
export function getWordInfo(uuid) {
    return request({
        method: 'get',
        url: `/api/gzf/word/info/${uuid}`,
    });
}

// 2022/06/30 王江毅 工单处理
export function wordDispose(data) {
    return request({
        method: 'PUT',
        url: '/api/gzf/word/checkout/dispose',
        data
    })
}

// 2022/06/30 王江毅 获取换房数据
export function getRenewalRoomChangeInfo(params) {
    return request({
        method: 'get',
        url: `/api/gzf/renewal/room/change/info`,
        params
    })
}

// 2022/06/30 王江毅 换房确认
export function renewalRoomChangeIsCanAudit(params) {
    return request({
        method: 'get',
        url: `/api/gzf/renewal/room/change/isCanAudit`,
        params
    });
}

// 2022/06/30 王江毅 指定换房确认人
export function renewalRoomChangeLeasingAudit(params) {
    return request({
        method: 'get',
        url: `/api/gzf/renewal/room/change/leasingAudit`,
        params
    });
}

// 2022/06/30 王江毅 换房新签
export function renewalRoomChangeNewContractSign(params) {
    return request({
        method: 'get',
        url: `/api/gzf/renewal/room/change/newContract/sign`,
        params
    });
}

// 2023/10/26 王江毅 保租房换房新签
export function renewalRoomChangeBzfNewContractSign(params) {
    return request({
        method: 'get',
        url: `/api/gzf/renewal/room/change/bzf/newContract/sign`,
        params
    });
}

// 2023/11/07 王江毅 退房工单审核和驳回接口
export function checkoutAuditRejectionApi(params) {
    return request({
        method: 'get',
        url: `/api/gzf/checkout/audit/rejection`,
        params
    });
}
// 2024-3-11-陈守亮-
export function newobjgongdanApi(params) {
    return request({
        method: 'get',
        url: `/api/gzf/contract/checkout/info`,
        params
    });
}

// 2024-3-27-陈守亮-获取视频地址
export function vivedperfectApi(uuid) {
    return request({
        method: 'get',
        url: `/api/gzf/word/work/order/video?videoUrl=${uuid}`,
    });
}
export function getWordInfoApi(params) {
    return request({
        method: 'get',
        url: `/api/gzf/checkout/audit/withdraw`,
        params
    });
}
// 2024/08/29 张晓瑜 押金转移工单列表
export function getTransferWordList(params) {
    return request({
        method: 'GET',
        url: '/api/gzf/deposit_transfer_work_order/web/page',
        params
    })
}
// 2024/08/29 张晓瑜 押金转移工单导出
export function transferwordExport(params) {
    return request({
        method: 'GET',
        url: '/api/gzf/deposit_transfer_work_order/web/export',
        params,
        responseType: 'blob',
    })
}
// 2024/08/30 张晓瑜 通过租户信息查询押金工单基础数据
export function getTransferWorkOrder(params) {
    return request({
        method: 'get',
        url: `/api/gzf/deposit_transfer_work_order/order/param`,
        params
    });
}
// 2024/08/30 张晓瑜 保存、提交押金工单
export function getSubmitTransferWorkOrder(data) {
    return request({
        method: 'post',
        url: `/api/gzf/deposit_transfer_work_order/order/submit`,
        data
    });
}
// 2024/08/30 张晓瑜 押金工单详情
export function getTransferWorkOrderInfo(params) {
    return request({
        method: 'GET',
        url: `/api/gzf/deposit_transfer_work_order/web/info`,
        params
    });
}
// 2024/08/30 张晓瑜 作废押金转移工单
export function cancellationTransferWorkOrderInfo(params) {
    return request({
        method: 'GET',
        url: `/api/gzf/deposit_transfer_work_order/order/cancellation`,
        params
    });
}
// 2024/09/02 张晓瑜 作废押金转移工单审核和驳回接口
export function transferWorkRejectionApi(params) {
    return request({
        method: 'get',
        url: `/api/gzf/deposit_transfer_work_order/order/audit`,
        params
    });
}
// 2024/09/02 张晓瑜 撤回押金工单
export function transferWorkWithdrawApi(params) {
    return request({
        method: 'get',
        url: `/api/gzf/deposit_transfer_work_order/order/withdraw`,
        params
    });
}
// 2024/09/02 张晓瑜 押金转移工单查询合同
export function getContractPageApi(params) {
    return request({
        method: 'get',
        url: `/api/gzf/deposit_transfer_work_order/web/info/contract_page`,
        params
    });
}

